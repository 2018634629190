import React from "react";
import logo from "../../assets/images/logo.png";
import "./legal-pages.styles.scss";

const LegalTermsOfUsePage = () => (
  <div className="legal-terms-of-use-page">
    <div className="legal-container ">
      <div className="legal-logo">
        <img className="legal-img" src={logo} alt="eliteLL grey logo" />
        <h2 className="cc-tagline">electronic liquor exchange</h2>
      </div>
      <div className="legal-container">
        <h1 className="legal-title">terms of service</h1>
        <p className="legal-body">
          Usage of elitell.com, eliteLL applications, and any other related websites and offerings (“Service”) is
          subject to these Terms of Use (“Terms”). These Terms are a legal agreement between you and eliteLL that states
          the terms and conditions under which you may use the Service. If you do not agree with the Terms you may not
          use the Service.
        </p>

        <h1 className="legal-title">1) Age restrictions</h1>
        <p className="legal-body">
          The Service is for persons 21 years of age and older. If you are under 21 years of age you may not use the
          Service or take part in any step of the Service process. The Service cannot be used on behalf of any person
          under 21 years of age. The Service cannot be used if any person under 21 years of age is or is intended to be
          part of the Service process.
        </p>

        <h1 className="legal-title">2) Access of services</h1>
        <p className="legal-body">
          EliteLL grants you a personal, non-transferable, non-sublicensable, non-commercial limited license to access
          use of the Service, subject to these Terms and the law. You may not use the Service or any of its contents for
          commercial use. Usage or viewing of the Service for any other reason besides shopping for alcohol is strictly
          prohibited. Any unauthorized use of the Service will terminate the license given by eliteLL. All rights not
          expressly given in these Terms are reserved. The Service reserves the right to refuse and/or revoke services
          to any person, persons or entity at any time for any reason.
        </p>

        <h1 className="legal-title">3) Shopping and purchases</h1>
        <p className="legal-body">
          EliteLL is a market service only and does not sell alcohol. All items listed on the Service come from a
          licensed alcohol retailer (“Retailer”) that has chosen to list their own inventory. The Service allows you to
          search for alcohol in a specified geographic area for inventory, prices and other information. When you “make
          offer” or “buy now” on the Service, it is an offer to purchase from a Retailer. Purchases happen when a
          Retailer accepts your offered buy price or you accept a Retailer’s offered sell price. Once a purchase occurs
          the Service will process your payment and initiate shipping.
        </p>

        <h1 className="legal-title">4) Large Purchases</h1>
        <p className="legal-body">
          Special arrangements must be made for purchases over $20,000.00 for both payment and transfer of ownership.
          eliteLL will contact you on this occasion.
        </p>

        <h1 className="legal-title">5) Shipping and handling</h1>
        <p className="legal-body">
          Shipments may be made by a third party shipping partner such as FedEx, UPS, GLS, or other shipping companies.
          All shipments of alcoholic beverages must be signed for by an individual capable of proving their age is 21
          years old or older. The signee must provide a valid state issued driver’s license or identification card at
          time of signing and allow it to be scanned. Shipments to PO/APO/FPO boxes are not allowed. Shipments can go to
          a commercial or residential address decided by you, or sent to a participating pick up location. Once your
          offer is accepted, shipment details cannot be changed. Errors in shipping information or inability to receive
          the shipment will result in the item being returned and a restocking fee of 15% of the cost of the order plus
          applicable fees.
        </p>

        <h1 className="legal-title">6) Retrieval</h1>
        <p className="legal-body">
          Once your purchase occurs, you grant eliteLL and/or eliteLL’s third party partners Limited Power of Attorney
          for retrieval of your purchased item. This Limited Power of Attorney includes pick-up, transportation, and
          drop-off of the purchased item. This Power of Attorney is effective when eliteLL’s and/or eliteLL’s third
          party partner’s pick-up of the purchased item and shall terminate upon drop-off of said purchased item. You
          are responsible for all legal requirements, in your state, to grant this Limited Power of Attorney including,
          but not limited to, two witnesses at the time of acceptance of these terms. Drop-off of alcoholic beverages
          must be signed for by an individual capable of proving their age is 21 years old or older. The signee must
          provide a valid state issued driver’s license or identification card at time of signing and allow it to be
          scanned.
        </p>

        <h1 className="legal-title">7) Returns, refunds, and exchanges</h1>
        <p className="legal-body">
          All sales of rare bottles through the Service are final. Returns of any kind are only considered at the sole
          discretion of the retailer if the rare bottle purchased is unopened and it is not damaged, spoiled or the
          wrong item. If a rare bottle purchase can be determined as damaged in transit, please contact
          santa0727@outlook.com so we can pursue a claim with the shipping company.
        </p>

        <h1 className="legal-title">8) Privacy</h1>
        <p className="legal-body">Use of the Service is subject to our Privacy Policy.</p>

        <h1 className="legal-title">9) Errors</h1>
        <p className="legal-body">
          Occasionally there may be information on our site or in the Service that contains typographical errors,
          inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product
          shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or
          omissions, and to change or update information or cancel orders if any information in the Service or on any
          related website is inaccurate at any time without prior notice (including after you have submitted your
          order). EliteLL undertakes no obligation to update, amend or clarify information in the Service or on any
          related website, including without limitation, pricing information, except as required by law. No specified
          update or refresh date applied in the Service or on any related website, should be taken to indicate that all
          information in the Service or on any related website has been modified or updated.
        </p>

        <h1 className="legal-title">10) Warranty disclaimer and limitation of liability</h1>
        <p className="legal-body">
          EliteLL does not guarantee, represent or warrant that your use of our service will be uninterrupted, timely,
          secure or error-free. EliteLL does not warrant that the results that may be obtained from the use of the
          Service will be accurate or reliable. You agree that from time to time we may remove the Service for
          indefinite periods of time or cancel the Service at any time, without notice to you. You expressly agree that
          your use of, or inability to use, the Service is at your sole risk. The Service and all products and services
          delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as
          available' for your use, without any representation, warranties or conditions of any kind, either express or
          implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for
          a particular purpose, durability, title, and non-infringement. In no case shall EliteLL, our directors,
          officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be
          liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential
          damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data,
          replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict
          liability or otherwise, arising from your use of any of the Service or any products procured using the
          Service, or for any other claim related in any way to your use of the Service or any product, including, but
          not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result
          of the use of the Service or any content (or product) posted, transmitted, or otherwise made available via the
          Service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion
          or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our
          liability shall be limited to the maximum extent permitted by law.
        </p>

        <h1 className="legal-title">11) Updates, modifications or termination</h1>
        <p className="legal-body">
          EliteLL reserves the right at any time to modify or discontinue the Service (or any part or content thereof)
          without notice at any time. EliteLL shall not be liable to you or to any third-party for any modification,
          price change, suspension or discontinuance of the Service.
        </p>

        <h1 className="legal-title">12) Intellectual property</h1>
        <p className="legal-body">
          EliteLL alone shall own all right, title and interest, including all related intellectual property rights, in
          and to all parts of the Service. This agreement is not a sale and does not convey to you any rights of
          ownership in or related to the Service, or any intellectual property rights owned by EliteLL. EliteLL names,
          EliteLL logos, and the product names associated with the Service are trademarks of EliteLL or third parties,
          and no right or license is granted to use them. You agree that you will not remove, alter or obscure any
          copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the
          Service.
        </p>

        <h1 className="legal-title">13) Changes to Terms of Service</h1>
        <p className="legal-body">
          You can review the most current version of the Terms of Service at any time at this page. We reserve the
          right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting
          updates and changes to our website. It is your responsibility to check our website periodically for changes.
          Your continued use of or access to our website or the Service following the posting of any changes to these
          Terms of Service constitutes acceptance of those changes.
        </p>

        <h1 className="legal-title">14) Governing Law</h1>
        <p className="legal-body">
          These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and
          construed in accordance with the laws of Los Angeles, California, USA.
        </p>

        <h1 className="legal-title">15) CA Prop 65 Warning:</h1>
        <p className="legal-body">
          Drinking distilled spirits, beer, coolers, wine and other alcoholic beverages may increase cancer risk, and,
          during pregnancy, can cause birth defects. For more information go to{" "}
          <a href="https://www.p65warnings.ca.gov/products/alcoholic-beverages">WWW.P65WARNINGS.CA.GOV/ALCOHOL </a>
        </p>
      </div>
    </div>
  </div>
);

export default LegalTermsOfUsePage;
