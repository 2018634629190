import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import logo from "../../assets/images/logo.png";
import "./legal-pages.styles.scss";

const initialForm = {
  email: "",
  first_name: "",
  last_name: "",
  message: "",
};

const ContactUsPage = () => {
  const [form, setForm] = useState(initialForm);

  const changeForm = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };
  const submitForm = (event) => {
    event.preventDefault();
    setTimeout(() => {
      setForm(initialForm);
      toast.success("Submitted successfully");
    }, 1000);
  };

  return (
    <div className="contact-us-page">
      <div className="contents">
        <img className="contact-img" src={logo} alt="eliteLL grey logo" />
        <h1 className="title">Contact Us</h1>
        <form className="form" onSubmit={submitForm.bind(this)}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" value={form.email} onChange={changeForm.bind(this)} required />
          </div>
          <div className="form-group">
            <label htmlFor="first_name">First Name</label>
            <input type="text" name="first_name" value={form.first_name} onChange={changeForm.bind(this)} required />
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name</label>
            <input type="text" name="last_name" value={form.last_name} onChange={changeForm.bind(this)} required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea type="text" name="message" value={form.message} onChange={changeForm.bind(this)} required />
          </div>
          <button className="submit" type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUsPage;
