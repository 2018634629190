import React from "react";
import hpBanner from "../../assets/images/eliqx-banner-hp-web-10-2021.jpg";
import hpBannerMobile from "../../assets/images/eliqx-banner-hp-mobile-10-2021.jpg";
import eliqxMainLogo from "../../assets/images/logo.png";
import "./shop.styles.scss";

const HomepageBanner = () => (
  <div className="homepage-banner">
    <div className="desktop-hp-banner-container">
      <img className="hp-banner" src={hpBanner} alt="eliteLL rare spirits bottle search" />
      <img className="hp-banner-mobile" src={hpBannerMobile} alt="eliteLL rare spirits bottle search" />
      <div className="banner-content">
        <div className="bannet-text">
          <div className="logo">
            <img className="hp-main-eliqx-logo" src={eliqxMainLogo} alt="eliteLL bid on rare spirits" />
            <h4 className="eliqx-main-h1">electronic liquor exchange</h4>
          </div>
          <div className="text">
            <h1 className="eliqx-title">AUTHENTIC RARE BOTTLES</h1>
            <h3 className="eliqx-subtitle">FROM LICENSED RETAILERS</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomepageBanner;
