import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./Vendor.js";

import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";
import HomePage from "./pages/home/home-page.component";
import PageNotFound from "./pages/page-not-found.component";
import BrowseProductsPage from "./pages/shop/browse-products/browse-products-page.component";
import ProductDetailPage from "./pages/shop/product-detail/product-detail-page.component";
import BuyerFaqPage from "./pages/legal/buyer-faq-page.component";

import LegalPrivacyPolicyPage from "./pages/legal/legal-privacy-policy-page.component";
import LegalRefundPolicyPage from "./pages/legal/legal-refund-policy-page.component";
import LegalTermsOfUsePage from "./pages/legal/legal-terms-of-use-page.component";
import LegalAboutPage from "./pages/legal/legal-about-page.component";
import ContactUsPage from "./pages/legal/contact-us-page.component";
import BuyerGuidePage from "./pages/legal/buyer-guide-page.component";

const App = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/legal/privacy-policy" element={<LegalPrivacyPolicyPage />} />
          <Route path="/legal/return-policy" element={<LegalRefundPolicyPage />} />
          <Route path="/legal/terms-of-use" element={<LegalTermsOfUsePage />} />
          <Route path="/buyer/faq" element={<BuyerFaqPage />} />
          <Route path="/legal/about" element={<LegalAboutPage />} />
          <Route path="/legal/contact-us" element={<ContactUsPage />} />
          <Route path="/shop/product/:productId" element={<ProductDetailPage />} />
          <Route path="/shop/products" element={<BrowseProductsPage />} />
          <Route path="/buyer/guide" element={<BuyerGuidePage />} />
          <Route element={<PageNotFound />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
