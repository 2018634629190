import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import HeaderMainMenu from "../shop/header-main-menu.component";
import { toggleHeaderProfileMenuShow, toggleHeaderMainMenuShow } from "../../redux/setting/setting.action";
import { createStructuredSelector } from "reselect";
import { getSearchKeyword } from "../../redux/shop/shop.selector";
import { setSearchKeyword } from "../../redux/shop/shop.action";
import "./header.styles.scss";

const Header = ({ toggleProfileMenuShow, toggleMainMenuShow, searchKey, setSearchKey }) => {
  const isIn = useRef();

  const searchInputHandle = (event) => {
    setSearchKey(event.target.value);
  };
  const showMainMenu = () => {
    toggleProfileMenuShow(false);
    toggleMainMenuShow(true);
  };
  const hideMenuMenu = () => {
    toggleProfileMenuShow(false);
    toggleMainMenuShow(false);
  };
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    isIn.current = true;

    window.addEventListener("scroll", () => {
      if (isIn.current) setScroll(window.scrollY > 30);
    });

    return () => {
      isIn.current = false;
    };
  }, []);

  return (
    <header className={scroll ? "header scrolled" : "header"} onMouseLeave={hideMenuMenu}>
      <ToastContainer autoClose={7000} draggable={false} />
      <div className="desktop-nav-container">
        <Link to="/" className="desktop-nav-logo">
          <img src={logo} alt="logo" />
        </Link>
        <form className="search-container">
          <input
            className="search-input"
            type="text"
            placeholder="search rare bottles"
            value={searchKey}
            onChange={searchInputHandle}
          />
          <button className="search-arrow" type="submit">
            <span className="icon-cc-right-arrow"></span>
          </button>
        </form>
        <div className="desktop-right-nav">
          <Link className="desktop-nav-link" to="#" onMouseEnter={hideMenuMenu}>
            <span className="icon-cc-watchlist"></span>
          </Link>
          <Link className="desktop-nav-link" to="#" onMouseOver={showMainMenu} replace={true}>
            <span className="icon-cc-menu"></span>
          </Link>
        </div>
      </div>
      <HeaderMainMenu />
    </header>
  );
};

const mapStateToProps = createStructuredSelector({
  searchKey: getSearchKeyword,
});

const mapDispatchToProps = (dispatch) => ({
  toggleProfileMenuShow: (val) => dispatch(toggleHeaderProfileMenuShow(val)),
  toggleMainMenuShow: (val) => dispatch(toggleHeaderMainMenuShow(val)),
  setSearchKey: (val) => dispatch(setSearchKeyword(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
