const MonthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const WeekLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const numberToPriceString = (x) => {
  if (isNaN(parseFloat(x))) return "-.--";
  return parseFloat(x)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const extractErrors = (err) => {
  let result = [];
  if (err.errors) {
    Object.keys(err.errors).map((key) => {
      result = [...result, ...err.errors[key]];
      return null;
    });
  }
  if (err.error) result.push(err.error);
  if (err.message) result.push(err.message);
  result.push("ERROR");
  return result;
};

export const isDigits = (val) => {
  return /^\d+$/.test(val);
};

export const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidPhoneNumber = (phone) => {
  return /^\d+$/.test(phone) && phone.length === 10;
};

export const strToPhoneNumberFormat = (txt) => {
  if (!txt || txt === undefined) return "";
  let result = "";
  for (let i = 0; i < txt.length && i < 10; i++) {
    if (i === 0) result += "(";
    if (i === 3) result += ") ";
    if (i === 6) result += "-";
    result += txt[i];
  }
  return result;
};

export const strToPhoneNumberString = (txt) => {
  return txt ? txt.replace(/[^0-9]/g, "").substr(0, 10) : "";
};

export const isAged = (aged = false) => {
  if (aged) {
    localStorage.setItem("age_verified", "true");
    return true;
  }
  return localStorage.getItem("age_verified") === "true";
};

export const isPassedProtection = (password = null) => {
  const protection = "eliteLL";
  if (localStorage.getItem("is_passed_protection") === protection) return true;
  if (password !== protection) return false;
  localStorage.setItem("is_passed_protection", password);
  return true;
};

export const removeAged = () => {
  localStorage.removeItem("age_verified");
};

export const convertDateTimeFormat = (dateTime) => {
  if (!dateTime || dateTime === undefined) return null;
  const dateVal = new Date(dateTime);
  const date = `${WeekLabels[dateVal.getDay()]}, ${
    MonthLabels[dateVal.getMonth()]
  } ${dateVal.getDate()}, ${dateVal.getFullYear()}`;
  let hours = dateVal.getHours();
  const minutes = dateVal.getMinutes();
  let pm = "AM";
  if (hours >= 12) {
    hours -= 12;
    pm = "PM";
  }
  if (hours <= 0) hours += 12;
  const time = `${parseInt(hours / 10)}${hours % 10}:${parseInt(minutes / 10)}${minutes % 10} ${pm}`;
  return { date, time };
};

export const twoDigitsString = (number) => {
  number = parseInt(number);
  return `${parseInt(number / 10)}${number % 10}`;
};

export const convertDateFormat = (date) => {
  let result = new Date(date);
  return `${result.getFullYear()}-${twoDigitsString(result.getMonth() + 1)}-${twoDigitsString(result.getDate())}`;
};

export const strToCardNumberFormat = (txt) => {
  if (!txt || txt === undefined) return "";
  txt = txt.replace(/[^0-9]/g, "").substr(0, 16);
  let result = "";
  for (let i = 0; i < txt.length; i++) {
    if (i && i % 4 === 0) result += " ";
    result += txt[i];
  }
  return result;
};

export const strToCardNumberString = (txt) => {
  return txt ? txt.replace(/[^0-9]/g, "").substr(0, 16) : "";
};

export const strToCardExpirationFormat = (txt) => {
  if (!txt || txt === undefined) return "";
  txt = txt.replace(/[^0-9]/g, "").substr(0, 4);
  if (txt.length <= 2) return txt;
  return `${txt.substr(0, 2)}/${txt.substr(2)}`;
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const strToDateFormat = (txt) => {
  if (!txt || txt === undefined) return "";
  txt = txt.replace(/[^0-9]/g, "").substr(0, 8);
  let result = "";
  for (let i = 0; i < txt.length; i++) {
    if (i === 2 || i === 4) result += "/";
    result += txt[i];
  }
  return result;
};

export const openPrintImage = (src) => {
  let image = new Image();
  image.src = `data:image/jpeg;base64,${src}`;
  const win = window.open("", "_blank");
  win.document.write(image.outerHTML);
  win.document.close();
  win.focus();
  win.window.onload = function () {
    win.window.print();
  };
};

export const strToFloat = (str) => {
  let result = "",
    flg = false;
  for (let i = 0; i < str.length; i++) {
    if (str[i] >= "0" && str[i] <= "9") result += str[i];
    else if (str[i] === "." && !flg) {
      flg = true;
      result += str[i];
    }
  }
  return result;
};
