import { applyMiddleware, combineReducers, createStore } from "redux";
import { thunk } from "redux-thunk";

import authReducer from "./auth/auth.reducer";
import settingReducer from "./setting/setting.reducer";
import shopReducer from "./shop/shop.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  setting: settingReducer,
  shop: shopReducer,
});

const middleWares = [thunk];

export const store = createStore(rootReducer, applyMiddleware(...middleWares));

export default store;
