import React from "react";
import { numberToPriceString } from "../../helpers/commonHelper";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "./shop.styles.scss";

import products from "../../data/products.json";

const PackageBox = ({ product }) => (
  <div className="top-pick-details">
    <p className="top-pick-detail">{product.size}</p>
    <p className="top-pick-detail">{product.abv}% ABV</p>
    {product.has_box !== null && <p className="top-pick-detail">{product.has_box ? "With" : "Without"} BOX</p>}
    {product.has_tag !== null && <p className="top-pick-detail">{product.has_tag ? "With" : "Without"} TAG</p>}
    {product.has_bag !== null && <p className="top-pick-detail">{product.has_bag ? "With" : "Without"} BAG</p>}
    {product.has_tin !== null && <p className="top-pick-detail">{product.has_tin ? "With" : "Without"} TIN</p>}
    {product.has_case !== null && <p className="top-pick-detail">{product.has_case ? "With" : "Without"} CASE</p>}
  </div>
);

const TopSellingProduct = () => {
  return (
    <div className="top-selling-products">
      <div className="carousel-title">
        <h2 className="category-title">eliteLL picks</h2>
      </div>
      <div className="top-selling-product-wrap">
        {products.map((product) => (
          <div key={product.id} className="top-pick-card">
            <div className="product-card-link">
              <Link to={`/shop/product/${product.id}`}>
                <img className="top-pick-img" src={product.images[0]} alt="eliteLL rare spirits product profile" />
              </Link>
              <div className="product-info">
                <Link to={`/shop/product/${product.id}`}>
                  <h2 className="top-pick-product-name">{product.name}</h2>
                </Link>
                <PackageBox product={product} />
                <div className="product-price-block">
                  <Link to={`/shop/product/${product.id}`}>
                    <span className="product-listing-current-price">${numberToPriceString(product.min_price)}</span>
                  </Link>
                  <span className="product-listing-status">lowest listing</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopSellingProduct;
