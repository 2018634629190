import React, { useLayoutEffect, useRef, useState } from "react";
import { numberToPriceString } from "../../../helpers/commonHelper";
import red_down_arrow from "../../../assets/images/red-down-arrow.svg";
import green_up_arrow from "../../../assets/images/green-up-arrow.svg";
import { Link, useParams } from "react-router-dom";
import ProductRelativeProducts from "../../../components/shop/product-relative-products.component";
import { Swiper, SwiperSlide } from "swiper/react";
import greyLeftArrow from "../../../assets/images/eliqx-grey-left-arrow.png";
import greyRightArrow from "../../../assets/images/eliqx-grey-right-arrow.png";
import whiteLogo from "../../../assets/images/logo.png";
import "./product-detail.styles.scss";

import products from "../../../data/products.json";

const middleDetails = [
  {
    label: "DISTILLERY",
    value: "distillery",
  },
  {
    label: "COMPANY",
    value: "company",
  },
  {
    label: "CATEGORY",
    value: "category",
  },
  {
    label: "SUB CATEGORY",
    value: "sub_category_1",
  },
  {
    label: "CLASSIFICATION",
    value: "classification",
  },
  {
    label: "BRAND",
    value: "brand",
  },
  {
    label: "COLLECTION",
    value: "collection",
  },
  {
    label: "SPECIFICATION",
    value: "specification",
  },
  {
    label: "RELEASE",
    value: "year_release",
  },
  {
    label: "AGED",
    value: "aged",
  },
];

const ProductDetailPage = () => {
  const params = useParams();
  const productId = params.productId;

  const product = products.find((x) => x.id === productId);

  const [lowestSellerProduct, setLowestSellerProduct] = useState();
  const [highestBid, setHighestBid] = useState();
  const [lastSold, setLastSold] = useState();
  const [chartData, setChartData] = useState();
  const graphHistoryDiv = useRef();
  const slider = useRef();
  const [chartSlug, setChartSlug] = useState("bids");

  const viewHistory = (slug) => {
    setChartSlug(slug);
    graphHistoryDiv.current.scrollIntoView({ behavior: "smooth" });
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const salesPercent =
    lowestSellerProduct && lastSold
      ? parseInt((parseFloat(lowestSellerProduct.price) / parseFloat(lastSold.price) - 1.0) * 100.0)
      : 0;

  return (
    <div className="product-detail-page">
      <div className="product-container">
        <div className="product-name">
          <div className="product-title-left">
            <h1 className="product-title">{product.name}</h1>
            <h5 className="product-variables">{product.size}</h5>
            <h5 className="product-variables">{product.abv}% ABV</h5>
            {product.has_box !== null && (
              <h5 className="product-variables">{product.has_box ? "With" : "Without"} BOX</h5>
            )}
            {product.has_tag !== null && (
              <h5 className="product-variables">{product.has_tag ? "With" : "Without"} TAG</h5>
            )}
            {product.has_bag !== null && (
              <h5 className="product-variables">{product.has_bag ? "With" : "Without"} BAG</h5>
            )}
            {product.has_tin !== null && (
              <h5 className="product-variables">{product.has_tin ? "With" : "Without"} TIN</h5>
            )}
            {product.has_case !== null && (
              <h5 className="product-variables">{product.has_case ? "With" : "Without"} CASE</h5>
            )}
          </div>
        </div>
        <div className="product-bottle">
          <div className="product-carousel">
            <Link to="#" className="product-rotate-button" onClick={() => slider.current.slideNext()} replace={true}>
              <img src={greyLeftArrow} alt="eliqx-grey-left-arrow.png" />
            </Link>
            <Swiper
              spaceBetween={1}
              slidesPerView={"auto"}
              onSwiper={(swiper) => {
                slider.current = swiper;
              }}
            >
              {product.images.map((img, i) => (
                <SwiperSlide key={i}>
                  <img className="bottle-main" src={img} alt={product.name} />
                </SwiperSlide>
              ))}
            </Swiper>
            <Link to="#" className="product-rotate-button" onClick={() => slider.current.slidePrev()} replace={true}>
              <img src={greyRightArrow} alt="eliqx-grey-right-arrow.png" />
            </Link>
          </div>
          <p className="cc-img-disclaimer">*not actual bottle shown</p>
          <div className="product-list-price">
            ${numberToPriceString(product.min_price)}
            <br />
            <span className="product-page-current-price">lowest list price</span>
          </div>
          <div className="price-change">
            <img
              className="price-change-down cc-not-active"
              src={red_down_arrow}
              alt="red_down-arrow"
              style={{ opacity: salesPercent >= 0 ? 0.27 : 1 }}
            />
            <img
              className="price-change-up"
              src={green_up_arrow}
              alt="green_up_arrow"
              style={{ opacity: salesPercent >= 0 ? 1 : 0.27 }}
            />
            <div className={`price-change-percent ${salesPercent >= 0 ? "cc-green" : "cc-red"}`}>
              {salesPercent}
              <span>%</span>
            </div>
          </div>
        </div>
        <div className="view-stats">
          <div className="view-details">
            <Link className="stats-link" to="#" onClick={viewHistory.bind(this, "bids")} replace={true}>
              highest offer <br /> ${numberToPriceString(highestBid?.price)}
            </Link>
          </div>
          <div className="view-details">
            <Link className="stats-link" to="#" onClick={viewHistory.bind(this, "orders")} replace={true}>
              last sold for <br /> ${numberToPriceString(lastSold?.price)}
            </Link>
          </div>
          <div className="view-details">
            <Link className="stats-link" to="#" onClick={viewHistory.bind(this, "listings")} replace={true}>
              available listings <br /> {product?.seller_products?.length ?? 0}
            </Link>
          </div>
        </div>
        <div className="product-vars">
          {middleDetails
            .filter((x) => !!product[x.value])
            .map((item) => (
              <p key={item.value} className="var-item">
                <span className="var-title">{item.label}</span>
                <span> </span>
                <Link to={`/shop/products?${item.value}=${product[item.value]}`}>
                  <span className="var-name">{product[item.value]}</span>
                </Link>
              </p>
            ))}
        </div>
        <div className="product-tasting-notes">
          {product.description ? (
            <>
              <h5 className="tasting-title">rare bottle description</h5>
              <p className="tasting-notes">{product.description}</p>
            </>
          ) : (
            <img src={whiteLogo} alt="white-logo" className="no-description-white-logo" />
          )}
        </div>
      </div>
      <div>
        <ProductRelativeProducts products={product?.similar_products} />
      </div>
    </div>
  );
};

export default ProductDetailPage;
