import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import { numberToPriceString } from "../../../helpers/commonHelper";
import { getSearchKeyword } from "../../../redux/shop/shop.selector";
import { setSearchKeyword } from "../../../redux/shop/shop.action";
import "./browse-products.styles.scss";

import products from "../../../data/products.json";

const PackageBox = ({ product }) => (
  <div className="product-details">
    <p className="product-detail">{product.size}</p>
    <p className="product-detail">{product.abv}% ABV</p>
    {product.has_box !== null && <p className="product-detail">{product.has_box ? "With" : "Without"} BOX</p>}
    {product.has_tag !== null && <p className="product-detail">{product.has_tag ? "With" : "Without"} TAG</p>}
    {product.has_bag !== null && <p className="product-detail">{product.has_bag ? "With" : "Without"} BAG</p>}
    {product.has_tin !== null && <p className="product-detail">{product.has_tin ? "With" : "Without"} TIN</p>}
    {product.has_case !== null && <p className="product-detail">{product.has_case ? "With" : "Without"} CASE</p>}
  </div>
);

const ProductCard = ({ product }) => (
  <div className="product-card" data-location={product.id}>
    <Link className="product-card-link" to={`/shop/product/${product.id}`}>
      <img className="product-img" src={product.images[0]} alt={product.id} />
      <div className="product-info">
        <h2 className="product-name">{product.name}</h2>
        <PackageBox product={product} />
        <div className="product-price-block">
          <span className="product-listing-current-price">${numberToPriceString(product.min_price)}</span>
          <br />
          <span className="product-listing-status">Lowest Listing</span>
        </div>
      </div>
    </Link>
  </div>
);

const priceOptions = [
  {
    id: 1,
    label: "< $500",
    value: { min: 0, max: 500 },
  },
  {
    id: 2,
    label: "$501 - $1000",
    value: { min: 501, max: 1000 },
  },
  {
    id: 3,
    label: "$1001 - $1500",
    value: { min: 1001, max: 1500 },
  },
  {
    id: 4,
    label: "> $1500",
    value: { min: 1500 },
  },
];

const packagingOptions = {
  has_box: "With Box",
  has_tag: "With Tag",
  has_bag: "With Bag",
  has_tin: "With Tin",
  has_case: "With Case",
};

const initialFilterOptions = {
  categories: [],
  brands: [],
  distilleries: [],
  specifications: [],
  lots: [],
  companies: [],
  sub_categories_1: [],
  classifications: [],
  collections: [],
  year_releases: [],
  ages: [],
  sizes: [],
};

const middleDetails = {
  distillery: "distilleries",
  company: "companies",
  category: "categories",
  sub_category_1: "sub_categories_1",
  classification: "classifications",
  brand: "brands",
  collection: "collections",
  specification: "specifications",
  year_release: "year_releases",
  aged: "ages",
  size: "sizes",
};

const optionLabels = {
  categories: "Category",
  brands: "Brand",
  distilleries: "Distillery",
  specifications: "Specification",
  lots: "Lot",
  companies: "Company",
  sub_categories_1: "Sub category",
  classifications: "Classifications",
  collections: "Collections",
  year_releases: "Release",
  ages: "Aged",
  sizes: "Size",
};

const getInitialFilterOptions = (location) => {
  let result = JSON.parse(JSON.stringify(initialFilterOptions));
  for (let i in middleDetails) {
    const val = new URLSearchParams(location.search).get(i);
    if (val && result[middleDetails[i]].findIndex((x) => x === val) <= -1) result[middleDetails[i]].push(val);
  }
  return result;
};

const BrowseProductsPage = () => {
  const [selectedPrices, setSelectedPrices] = useState([]);
  const filterOptions = initialFilterOptions;
  const [selectedOptions, setSelectedOptions] = useState(getInitialFilterOptions(useLocation()));
  const [isListed, setIsListed] = useState(0);
  const [selectedSort, setSelectedSort] = useState();
  const [filterShow, setFilterShow] = useState(true);
  const [show, setShow] = useState(false);
  const [subShow, setSubShow] = useState({ availability: true });
  const [selectedPackaging, setSelectedPackaging] = useState([]);
  const [abvRange, setAbvRange] = useState(null);
  const allProducts = products;

  const toggleSubShow = (k) => {
    setSubShow({ ...subShow, [k]: !subShow[k] });
  };
  const isSelectedOption = useCallback(
    (id, val) => {
      return selectedOptions[id].findIndex((x) => x === val) > -1;
    },
    [selectedOptions]
  );
  const toggleSelectedOption = useCallback(
    (id, val) => {
      let temp = [];
      if (isSelectedOption(id, val)) {
        temp = selectedOptions[id].filter((x) => x !== val);
      } else {
        temp = [...selectedOptions[id], val];
      }
      setSelectedOptions({ ...selectedOptions, [id]: temp });
    },
    [isSelectedOption, selectedOptions]
  );
  const isSelectedPrice = useCallback(
    (id) => {
      return selectedPrices.findIndex((x) => x.id === id) > -1;
    },
    [selectedPrices]
  );
  const isSelectedPackaging = useCallback(
    (x) => {
      return selectedPackaging.findIndex((t) => t === x) > -1;
    },
    [selectedPackaging]
  );
  const togglePriceFilter = useCallback(
    (item) => {
      if (isSelectedPrice(item.id)) {
        setSelectedPrices(selectedPrices.filter((x) => x.id !== item.id));
      } else {
        setSelectedPrices([...selectedPrices, item]);
      }
    },
    [selectedPrices, isSelectedPrice]
  );
  const togglePackagingFilter = useCallback(
    (x) => {
      if (isSelectedPackaging(x)) setSelectedPackaging(selectedPackaging.filter((t) => t !== x));
      else setSelectedPackaging([...selectedPackaging, x]);
    },
    [selectedPackaging, isSelectedPackaging]
  );
  const selectSortKey = (key) => {
    setSelectedSort(key);
  };

  return (
    <div className="browse-products-page">
      <div className="top-picks-container">
        <div className="showallProduct show-filter">
          <div className="filter">
            <div className="filter-button">
              <h2 onClick={() => setShow(!show)}>
                <span className="icon-cc-filter"></span> Filters
              </h2>
              <button onClick={() => setFilterShow(!filterShow)}>{filterShow ? "Hide" : "Show"}</button>
            </div>
            <div className="tags">
              {Object.keys(selectedOptions).map((key) =>
                selectedOptions[key].map((val, i) => (
                  <Link key={`${key}_${val}_${i}`} to="#" onClick={toggleSelectedOption.bind(this, key, val)}>
                    {val} <img src={logo} alt="blue-logo" />
                  </Link>
                ))
              )}
              {priceOptions
                .filter((x) => isSelectedPrice(x.id))
                .map((item) => (
                  <Link to="#" key={item.id} onClick={togglePriceFilter.bind(this, item)} replace={true}>
                    {item.label} <img src={logo} alt="blue-logo" />
                  </Link>
                ))}
              {Object.keys(packagingOptions)
                .filter((x) => isSelectedPackaging(x))
                .map((item) => (
                  <Link to="#" key={item} onClick={togglePackagingFilter.bind(this, item)} replace={true}>
                    {packagingOptions[item]} <img src={logo} alt="blue-logo" />
                  </Link>
                ))}
              {abvRange === 1 && (
                <Link to="#" onClick={() => setAbvRange(null)} replace={true}>
                  {"<= 50%"}
                  <img src={logo} alt="blue-logo" />
                </Link>
              )}
              {abvRange === 2 && (
                <Link to="#" onClick={() => setAbvRange(null)} replace={true}>
                  {"> 50%"}
                  <img src={logo} alt="blue-logo" />
                </Link>
              )}
            </div>
            <div className="sort">
              <div className="wrap">
                <h2>Sort</h2>
                <div className="option">
                  <input
                    type="radio"
                    id="htl"
                    name="sort-by"
                    checked={selectedSort === "price_to_low"}
                    onChange={() => {}}
                    onClick={() => selectSortKey("price_to_low")}
                  />
                  <label htmlFor="htl">Price: High to Low</label>
                  <input
                    type="radio"
                    id="lth"
                    name="sort-by"
                    checked={selectedSort === "price_to_high"}
                    onChange={() => {}}
                    onClick={() => selectSortKey("price_to_high")}
                  />
                  <label htmlFor="lth">Price: Low to High</label>
                  <input
                    type="radio"
                    id="a-z"
                    name="sort-by"
                    checked={selectedSort === "alphabetically"}
                    onChange={() => {}}
                    onClick={() => selectSortKey("alphabetically")}
                  />
                  <label htmlFor="a-z">Alphabetically A - Z</label>
                  <input
                    type="radio"
                    id="newest"
                    name="sort-by"
                    checked={selectedSort === "newest"}
                    onChange={() => {}}
                    onClick={() => selectSortKey("newest")}
                  />
                  <label htmlFor="newest">Newest</label>
                </div>
              </div>
            </div>
          </div>
          {filterShow && (
            <div className={`select-filter-data ${show ? "show" : ""}`}>
              <div className="filtar-data-wrap">
                <div className="close" onClick={() => setShow(!show)}>
                  <span className="icon-cc-x-logo"></span>
                </div>
                <div className="filter-by">
                  <h2>
                    Availability
                    <Link to="#" onClick={() => toggleSubShow("availability")} replace={true}>
                      <i className={subShow.availability ? "icon-cc-up-arrow Show" : "icon-cc-down-arrow Hide"}></i>
                    </Link>
                  </h2>
                  {subShow.availability && (
                    <div className="options">
                      <div className="radio">
                        <input
                          className="radio-input"
                          type="checkbox"
                          name="availability"
                          id="availability1"
                          checked={!!(isListed & 1)}
                          onChange={() => {}}
                          onClick={() => setIsListed(isListed ^ 1)}
                        />
                        <label className="radio-label" htmlFor="availability1">
                          In Stock
                        </label>
                      </div>
                      <div className="radio">
                        <input
                          className="radio-input"
                          type="checkbox"
                          name="availability"
                          id="availability2"
                          checked={!!(isListed & 2)}
                          onChange={() => {}}
                          onClick={() => setIsListed(isListed ^ 2)}
                        />
                        <label className="radio-label" htmlFor="availability2">
                          Out of Stock
                        </label>
                      </div>
                    </div>
                  )}
                </div>
                {filterOptions &&
                  Object.keys(filterOptions).map(
                    (key) =>
                      optionLabels[key] && (
                        <div className="filter-by" key={key}>
                          <h2>
                            {optionLabels[key]}
                            <Link to="#" onClick={() => toggleSubShow(key)} replace={true}>
                              <i className={subShow[key] ? "icon-cc-down-arrow Hide" : "icon-cc-up-arrow Show"}></i>
                            </Link>
                          </h2>
                          {!subShow[key] && (
                            <div className="options">
                              {filterOptions[key].map((val, i) => (
                                <div className="radio" key={i}>
                                  <input
                                    className="radio-input"
                                    type="checkbox"
                                    name={key}
                                    id={`${key}_${i}`}
                                    checked={isSelectedOption(key, val)}
                                    onChange={() => {}}
                                    onClick={toggleSelectedOption.bind(this, key, val)}
                                  />
                                  <label className="radio-label" htmlFor={`${key}_${i}`}>
                                    {val}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      )
                  )}
                <div className="filter-by">
                  <h2>Prices</h2>
                  <div className="options">
                    {priceOptions.map((item) => (
                      <div className="radio" key={item.id}>
                        <input
                          className="radio-input"
                          type="checkbox"
                          name="prices"
                          id={`prices_${item.id}`}
                          checked={isSelectedPrice(item.id)}
                          onChange={() => {}}
                          onClick={togglePriceFilter.bind(this, item)}
                        />
                        <label className="radio-label" htmlFor={`prices_${item.id}`}>
                          {item.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="filter-by">
                  <h2>Packaging</h2>
                  <div className="options">
                    {Object.keys(packagingOptions).map((item) => (
                      <div className="radio" key={item}>
                        <input
                          className="radio-input"
                          type="checkbox"
                          name="prices"
                          id={`packaging_${item}`}
                          checked={isSelectedPackaging(item)}
                          onChange={() => {}}
                          onClick={togglePackagingFilter.bind(this, item)}
                        />
                        <label className="radio-label" htmlFor={`packaging_${item}`}>
                          {packagingOptions[item]}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="filter-by">
                  <h2>ABV</h2>
                  <div className="options">
                    <div className="radio">
                      <input
                        className="radio-input"
                        type="checkbox"
                        id="abv_1"
                        name="prices"
                        checked={abvRange === 1}
                        onChange={() => {}}
                        onClick={() => setAbvRange(1)}
                      />
                      <label className="radio-label" htmlFor={`abv_1`}>
                        {"<= 50%"}
                      </label>
                    </div>
                    <div className="radio">
                      <input
                        className="radio-input"
                        type="checkbox"
                        id="abv_2"
                        name="prices"
                        checked={abvRange === 2}
                        onChange={() => {}}
                        onClick={() => setAbvRange(2)}
                      />
                      <label className="radio-label" htmlFor={`abv_2`}>
                        {"> 50%"}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={filterShow ? "all-products-row" : "all-products-row full"}>
            {products && products.length > 0 ? (
              products.map((product) => <ProductCard key={product.id} product={product} />)
            ) : (
              <>
                <h5 className="cc-h5">That bottle is not yet available. Please browse below for available bottles.</h5>
                {allProducts && allProducts.map((product) => <ProductCard key={product.id} product={product} />)}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  keyword: getSearchKeyword,
});

const mapDispatchToProps = (dispatch) => ({
  setKeyword: (word) => dispatch(setSearchKeyword(word)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrowseProductsPage);
