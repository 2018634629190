import React from "react";
import logo from "../../assets/images/logo.png";
import "./legal-pages.styles.scss";

const LegalRefundPolicyPage = () => (
  <div className="legal-refund-policy-page">
    <div className="legal-container ">
      <div className="legal-logo">
        <img className="legal-img" src={logo} alt="eliteLL grey logo" />
        <h2 className="cc-tagline">electronic liquor exchange</h2>
      </div>
      <div className="legal-container">
        <h1 className="legal-title">return policy</h1>
        <p className="legal-body">
          All sales of rare bottles through eliteLL are final. Returns of any kind are only considered at the sole
          discretion of the retailer if the rare bottle purchased is unopened and it is damaged, spoiled or the wrong
          item.
          <br />
          If a rare bottle purchase can be determined as damaged in transit, please contact santa0727@outlook.com so we
          can pursue a claim with the shipping company.
        </p>
      </div>
    </div>
  </div>
);

export default LegalRefundPolicyPage;
