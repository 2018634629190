import React from "react";
import logo from "../../assets/images/logo.png";
import "./legal-pages.styles.scss";

const LegalAboutPage = () => (
  <div className="legal-about-page">
    <div className="legal-container ">
      <div className="legal-logo">
        <img className="legal-img" src={logo} alt="eliteLL grey logo" />
        <h2 className="cc-tagline">electronic liquor exchange</h2>
      </div>
      <div className="legal-container">
        <h1 className="legal-title">about eliteLL</h1>
        <p className="legal-section-title">electronic liquor exchange</p>
        <p className="legal-body">
          There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in
          some form, by injected humour, or randomised words which don't look even slightly believable. If you are going
          to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of
          text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making
          this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a
          handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem
          Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
        </p>
      </div>
    </div>
  </div>
);

export default LegalAboutPage;
