import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./footer.styles.scss";
import logo from "../../assets/images/logo.png";
import credit_card_png from "../../assets/images/credit-card-payments-logos.png";

const Footer = () => {
  const backtotop = { display: "none" };
  const checkScroll = () => {
    var scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
    if (scrollTop > 350) {
      if (document.getElementById("back-to-top") != null) {
        document.getElementById("back-to-top").setAttribute("style", "display:block");
      }
    } else {
      if (document.getElementById("back-to-top") != null) {
        document.getElementById("back-to-top").setAttribute("style", "display:none");
      }
    }
  };
  const clickToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };
  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  return (
    <div className="footer-component">
      <footer>
        <div className="page-width">
          <div className="footer-top">
            <div className="about">
              <h4>About</h4>
              <p>eliteLL, an adult beverage focused application for a new era of at home consumption.</p>
              <div className="credit-card-div">
                <img src={credit_card_png} alt="credit-card-png" />
                <p>Payments powered by stripe</p>
              </div>
            </div>
            <div className="links-col">
              <h4>Buyer Info</h4>
              <ul>
                <li>
                  <Link to="/buyer/guide" onClick={clickToTop}>
                    How does it work?
                  </Link>
                </li>
                <li>
                  <Link to="/buyer/faq" onClick={clickToTop}>
                    Buyer FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="links-col">
              <h4>Fine Print</h4>
              <ul>
                <li>
                  <Link to="/legal/terms-of-use" onClick={clickToTop}>
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link to="/legal/privacy-policy" onClick={clickToTop}>
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/legal/return-policy" onClick={clickToTop}>
                    Refund Policy
                  </Link>
                </li>
                <li>
                  <Link to="/legal/contact-us" onClick={clickToTop}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom">
            <img src={logo} alt="yellow" />
            <p>Copyright © 2024 All Rights Reserved EliteLL.</p>
          </div>
        </div>
      </footer>
      <div id="back-to-top" style={backtotop} onClick={clickToTop}>
        <Link to="#" className="top arrow" replace={true}>
          <span className="icon-cc-up-arrow"></span>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
