import React from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import "./shop.styles.scss";

import products from "../../data/products.json";

const PackageBox = ({ product }) => (
  <div className="top-pick-details">
    <p className="top-pick-detail">{product.size}</p>
    <p className="top-pick-detail">{product.abv}% ABV</p>
    {product.has_box !== null && <p className="top-pick-detail">{product.has_box ? "With" : "Without"} BOX</p>}
    {product.has_tag !== null && <p className="top-pick-detail">{product.has_tag ? "With" : "Without"} TAG</p>}
    {product.has_bag !== null && <p className="top-pick-detail">{product.has_bag ? "With" : "Without"} BAG</p>}
    {product.has_tin !== null && <p className="top-pick-detail">{product.has_tin ? "With" : "Without"} TIN</p>}
    {product.has_case !== null && <p className="top-pick-detail">{product.has_case ? "With" : "Without"} CASE</p>}
  </div>
);

const TrendingRareProducts = () => {
  return (
    <div className="top-selling-products">
      <div className="carousel-title">
        <h2 className="category-title">Trending</h2>
      </div>
      <div className="top-selling-product-wrap trending_product">
        {products.map((product, index) => (
          <div key={product.id} className="top-pick-card">
            <Link to={`/shop/product/${product.id}`}>
              <div className="product-card-link">
                <div className="left_content">
                  <div className="card_number">
                    {index < 5 ? "0" : ""}
                    {index + 1}
                  </div>
                  <div className="product_image">
                    <img className="top-pick-img" src={product.images[0]} alt="eliteLL rare spirits product profile" />
                  </div>
                </div>
                <div className="product-info">
                  <h2 className="top-pick-product-name">{product.name}</h2>
                  <PackageBox product={product} />
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrendingRareProducts;
