import React from "react";
import { Container } from "reactstrap";
import HomepageAllProducts from "../../components/shop/homepage-all-products.component";
import HomepageBanner from "../../components/shop/homepage-banner.component";
import HomepageVideoBanner from "../../components/shop/homepage-video-banner.component";
import TopSellingProduct from "../../components/shop/top-selling-product.component";
import TrendingRateProducts from "../../components/shop/trending-rare-products.component";
import "./home-page.styles.scss";

export const HomePage = () => (
  <div className="home-page">
    <div id="content" className="site-content" tabIndex={-1}>
      <div className="content-wrapper content-wrapper-vc-enabled">
        <div id="primary" className="content-area">
          <main id="main" className="site-main">
            <article id="post-13069" className="post-13069 page type-page status-publish hentry">
              <div className="entry-content">
                <HomepageBanner />
                <Container>
                  <TopSellingProduct />
                </Container>
                <Container>
                  <TrendingRateProducts />
                </Container>
                <Container>
                  <HomepageAllProducts />
                </Container>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>
  </div>
);

export default HomePage;
