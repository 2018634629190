import React from "react";
import logo from "../../assets/images/logo.png";
import "./legal-pages.styles.scss";

const BuyerFaqPage = () => (
  <div className="buyer-faq-page">
    <div className="legal-container ">
      <div className="legal-logo">
        <img className="legal-img" src={logo} alt="eliteLL grey logo" />
        <h2 className="cc-tagline">electronic liquor exchange</h2>
      </div>
      <div className="legal-container">
        <h1 className="legal-title">buyer FAQ</h1>
        <p className="legal-section-title">Do I need to be 21 years of age or older to use eliteLL?</p>
        <p className="legal-body">Yes. There is a mandatory age verification with every order and delivery.</p>
        <p className="legal-section-title">In which cities can I use eliteLL?</p>
        <p className="legal-body">
          eliteLL is currently able to ship almost anywhere in the World. Although, some cities and/or counties have
          local laws that don’t allow for our services. Please check with your local governing entity if you are unsure.
        </p>
        <p className="legal-section-title">Who can sell on eliteLL?</p>
        <p className="legal-body">
          Only licensed liquor retailers may sell on our platform. Here are some of the requirements that must be met to
          be approved retailer on our exchange.
        </p>
        <p className="legal-section-title">What is the difference between a BUY and MAKE OFFER?</p>
        <p className="legal-body">
          When you see an asking price <strong>LISTING</strong> that you are willing to pay, you can{" "}
          <strong>BUY</strong> that item immediately and initiate the shipping process. If you think the lowest{" "}
          <strong>LISTING</strong> is too high, you are welcome to submit a price you are willing to pay{" "}
          <strong>OFFER</strong> for below the lowest <strong>LISTING</strong> price, and choose how long your{" "}
          <strong>OFFER</strong> is available. A seller may accept your <strong>OFFER</strong> at any time. Once an{" "}
          <strong>OFFER</strong> is accepted the transaction proceeds and the shipping process begins.
        </p>
        <p className="legal-section-title">Can I make an OFFER on a bottle that has no current LISTING price?</p>
        <p className="legal-body">
          Yes! In fact, you should. When there is an OFFER with no LISTING we will keep an eye out for that bottle.
          Also, new inventory is added all the time, and you never know when something will pop up.
        </p>
        <p className="legal-section-title">How is sales tax calculated?</p>
        <p className="legal-body">
          Sales tax is calculated based on the location of the seller. In the shopping/purchasing process sales tax is
          estimated based on the highest California sales tax rate of 10.5%. The correct sales tax is applied upon
          purchase.
        </p>
        <p className="legal-section-title">If I don’t see a rare bottle I want, can I request that it be added?</p>
        <p className="legal-body">
          Yes. Please email <a href="mailto:santa0727@outlook.com">santa0727@outlook.com</a> and let us know what you
          are looking for.
        </p>
        <p className="legal-section-title">What should I expect for delivery?</p>
        <p className="legal-body">
          Delivery may take between 4-8 business days. This estimate is subject to other factors such as the time of
          day, order volume, fulfillment location, etc. An adult signature is always required.
        </p>
        <p className="legal-section-title">How long does it take for my order to be delivered?</p>
        <p className="legal-body">
          Delivery can take 5-10 business days. This estimate is subject to other factors such as the time of day, order
          volume, fulfillment location, etc.
        </p>
        <p className="legal-section-title">Can I return the rare bottle that I bought?</p>
        <p className="legal-body">
          All sales of rare bottles are usually final. However, if there is an outstanding situation where a refund may
          be warranted, it is up to the individual retailer’s determination. For outstanding circumstances please email{" "}
          <a href="mailto:santa0727@outlook.com">santa0727@outlook.com</a>
        </p>
        <p className="legal-section-title">
          Why would a bottle not have the original packaging (tag/bag/box/tin/casing)?
        </p>
        <p className="legal-body">
          There are many circumstances where the retailer does not get the original packaging when they receive the
          bottle from their distributor. It is also a common occurrence for original packaging to be damaged or lost in
          transit from the manufacturer to the distributor to the retailer. If a rare bottle is being sold without the
          original packaging (a cardboard box for example) it doesn't necessarily mean the bottle is bad. However, if
          you want a rare bottle with specific packaging, make sure to look for exactly what you want in the specifics
          of each listing.
        </p>
      </div>
    </div>
  </div>
);

export default BuyerFaqPage;
